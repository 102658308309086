"use client";

import * as React from "react";
import { forwardRef } from "react";
import { useFormStatus } from "react-dom";

import { type IconProps, Icons } from "~/components/icons";
import { Button, type ButtonProps } from "~/components/ui/button";
import { cn } from "~/lib/utils";

interface Props extends ButtonProps {
  isLoading?: boolean;
  Icon?: (props: IconProps) => React.ReactNode;
  hideChildrenWhenLoading?: boolean;
  hideSpinner?: boolean;
}

export const LoadingButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      Icon,
      isLoading,
      children,
      hideChildrenWhenLoading,
      hideSpinner,
      ...props
    },
    ref,
  ) => {
    const status = useFormStatus();
    const pending = props.type === "button" ? false : status.pending;
    const isReallyLoading = isLoading ?? pending;

    return (
      <Button {...props} ref={ref} disabled={isReallyLoading || props.disabled}>
        {isReallyLoading ? (
          hideSpinner ? null : (
            <Icons.spinner
              className={cn("h-4 w-4 animate-spin", {
                "mr-2": !!children,
              })}
            />
          )
        ) : (
          Icon && (
            <Icon
              className={cn("h-4 w-4", {
                "mr-2": !!children,
              })}
            />
          )
        )}{" "}
        {isReallyLoading && hideChildrenWhenLoading ? null : children}
      </Button>
    );
  },
);

LoadingButton.displayName = "LoadingButton";

export function PlainLoadingButton({
  Icon,
  isLoading,
  children,
  hideChildrenWhenLoading,
  className,
  ...props
}: Props) {
  const status = useFormStatus();
  const isReallyLoading = isLoading ?? status.pending;
  return (
    <button
      {...props}
      disabled={isReallyLoading || props.disabled}
      className={cn("flex flex-row", className)}
    >
      {isReallyLoading ? (
        <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
      ) : (
        Icon && <Icon className="mr-2 h-4 w-4" />
      )}{" "}
      {isReallyLoading && hideChildrenWhenLoading ? null : children}
    </button>
  );
}
