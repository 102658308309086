"use client";

import { TestTube2Icon } from "lucide-react";

import { BindActions } from "~/components/command/provider";
import { type ActionRecord } from "~/components/command/types";
import { type getMyGroups } from "~/server/data/groups";

interface Props {
  groups: Awaited<ReturnType<typeof getMyGroups>>;
}

export const AppActions = ({ groups }: Props) => {
  const actions = {
    "user.groups.list": {
      Icon: TestTube2Icon,
      type: "redirect-group",
      groupByTitle: "Grupper",
      prefix: "g:",
      items: groups.map((group) => ({
        label: group.name,
        url: `/larare/${group.id}`,
      })),
    },
  } satisfies ActionRecord;
  return (
    <>
      <BindActions actions={actions} />
    </>
  );
};
